<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white fixed md:static"
		>
			<span class="ml-2 font-bold">Utilidades</span>
			<div>
				<span class="text-white font-bold">Filtro Estado: </span>
				<select
					v-model="filtro"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none font-bold"
				>
					<option :value="null" selected>Entregados</option>
					<option value="anulado">Anulados</option>
				</select>
			</div>
			<div>
				<span class="text-white font-bold">Filtro Cliente: </span>
				<select
					v-model="filtroCliente"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none font-bold"
				>
					<option :value="false" selected>Desactivado</option>
					<option :value="true">Activado</option>
				</select>
			</div>
			<div class="flex items-center justify-center">
				<router-link to="/Cotizar">
					<button
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<Add class="w-6 h-6 text-white fill-current" />
						<span class="underline ml-2">Realizar cotización</span>
					</button></router-link
				>
			</div>
		</div>
		<div class="md:flex md:justify-between md:mt-0 mt-20">
			<div
				class="w-full md:w-2/6 md:ml-5 border-gray-300 border h-32 rounded-lg grid items-center"
			>
				<div class="flex ml-12">
					<div>
						<span class="font-bold mr-2">Fecha Inicio:</span>
						<input
							class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							type="date"
							v-model="fechaInicio"
						/>
					</div>
					<div class="w-36">
						<span class="font-bold mx-2">Fecha Fin:</span>
						<input
							class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							type="date"
							v-model="fechaFin"
						/>
					</div>
				</div>
				<div class="flex justify-around">
					<Botonp
						class="underline"
						botonpText="Reset"
						@click="resetData()"
					></Botonp>
					<Botonp
						class="underline"
						botonpText="Buscar"
						@click="nuevaBusqueda()"
					></Botonp>
				</div>
			</div>

			<div v-if="mostrarTotal">
				<div class="flex flex-wrap content-center justify-end">
					<div class="">
						<div class="mr-3 flex content-center flex-wrap">
							<div class="font-bold">Comisiones Total USD:</div>
						</div>
						<Campo
							:campoCoti="formato(utilidadesQuery.comisiones_vendedor_usd)"
							class="mr-10"
						/>
					</div>
				</div>
				<div class="flex flex-wrap content-center justify-end mt-3">
					<div class="">
						<div class="mr-3 flex content-center flex-wrap">
							<div class="font-bold">Comisiones Total Bs:</div>
						</div>
						<Campo
							:campoCoti="formato(utilidadesQuery.comisiones_vendedor_bs)"
							class="mr-10"
						/>
					</div>
				</div>
			</div>
			<div v-if="mostrarTotal">
				<div class="flex flex-wrap content-center justify-end">
					<div class="font-bold">
						<div class="mr-3 flex content-center flex-wrap">
							<div class="">Utilidad Total USD:</div>
						</div>
						<Campo
							:campoCoti="formato(utilidadesQuery.utilidades_totales_usd)"
							class="mr-10"
						/>
					</div>
				</div>
				<div class="flex flex-wrap content-center justify-end mt-3">
					<div class="font-bold">
						<div class="mr-3 flex content-center flex-wrap">
							<div class="">Utilidad Total Bs:</div>
						</div>
						<Campo
							:campoCoti="formato(utilidadesQuery.utilidades_totales_bs)"
							class="mr-10"
						/>
					</div>
				</div>
			</div>
			<div
				v-if="filtroCliente"
				class="w-full md:w-1/5 md:mr-10 border-gray-300 border h-32 rounded-lg grid items-center"
			>
				<div class="flex justify-center">
					<span class="font-bold">Seleccione Nombre del Cliente:</span>
				</div>
				<div class="flex justify-center pb-5">
					<vSelect
						class="w-64 border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center focus:outline-none"
						:options="usuariosArraySelect"
						v-model="nombreCliente"
						@input="idCliente()"
					></vSelect>
				</div>
			</div>
		</div>
		<div class="flex justify-center my-4">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<table class="table-auto mt-20 mx-10 md:mt-0">
			<thead>
				<tr>
					<th class="border-2 px-4 py-2">#</th>
					<th class="border-2 px-4 py-2">Cliente</th>
					<th class="border-2 px-4 py-2">Fecha</th>
					<th class="border-2 px-4 py-2">Vendedor</th>
					<th class="border-2 px-4 py-2">Precio Proveedor con descuento Bs</th>
					<th class="border-2 px-4 py-2">Precio Proveedor con descuento $</th>
					<th class="border-2 px-4 py-2">Precio de venta Bs</th>
					<th class="border-2 px-4 py-2">Precio de venta $</th>
					<th class="border-2 px-4 py-2">Comision vendedor</th>
					<th class="border-2 px-4 py-2">Utilidad final</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(compra, k) in utilidadesQuery.array_utilidades" :key="k">
					<td class="border-2 px-4 py-2 text-center">
						<router-link
							class="hover:text-blue-500 hover:underline"
							:to="{
								name: 'PedidoID',
								params: {
									id: compra.id.padStart(4, '0')
								}
							}"
							>{{ compra.id.padStart(4, "0") }}</router-link
						>
					</td>
					<td class="border-2 px-4 py-2 text-center">
						<router-link
							class="hover:text-blue-500 hover:underline"
							:to="{
								name: 'DetalleCliente',
								params: {
									id: parseInt(compra.cliente_id),
									name: compra.cliente_nombre.replace(/\s/g, '_')
								}
							}"
							>{{ firstLetterUpperCase(compra.cliente_nombre) }}</router-link
						>
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formatoFecha(compra.fecha_entrega) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ firstLetterUpperCase(compra.vendedor_nombre) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.precio_proveedorBs) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.precio_proveedorUsd) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.precio_ventaBs) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.precio_ventaUsd) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.comision_vendedor) }}
					</td>
					<td class="border-2 px-4 py-2 text-center">
						{{ formato(compra.utilidad_final) }}
					</td>
				</tr>
			</tbody>
		</table>
		<div class="flex justify-center my-4">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div class="flex justify-between">
			<div class="pl-32 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="dataInicio > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="utilidadesQuery.continua_data"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import Add from "@/components/añadir";
import Botonp from "@/components/botonPrimario";
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import Campo from "@/components/camposCotizacion";
import { formato, firstLetterUpperCase, formatoFecha } from "@/functions.js";

export default {
	data() {
		return {
			dataInicio: 0,
			dataFin: 20,
			filtro: null,
			fechaInicio: null,
			fechaFin: null,
			utilidadesQuery: {},
			message: "",
			showMessage: false,
			mostrarTotal: false,
			clienteId: null,
			usuarios: [],
			usuariosArraySelect: [],
			filtroCliente: false,
			nombreCliente: null
		};
	},
	components: {
		Add,
		Botonp,
		Campo,
		vSelect
	},
	async created() {
		this.$store.state.isLoading = true;
		await this.queryUtilidades();
		await this.usuariosQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		idCliente() {
			let nombreTelefonoCliente = [];
			nombreTelefonoCliente = this.usuarios.find(
				e => e.name == this.nombreCliente
			);
			this.clienteId = nombreTelefonoCliente.id;
		},
		resetData() {
			this.clienteId = null;
			this.filtroCliente = false;
			this.dataInicio = 0;
			this.dataFin = 20;
			this.fechaInicio = null;
			this.fechaFin = null;
		},
		async usuariosQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/usuarios.gql")
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.usuarios = data.data.usuarios;
					this.usuariosArraySelect = this.usuarios.map(x => {
						return x.name;
					});
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		nuevaBusqueda() {
			//Validacion de data
			let fechaInicio = new Date(this.fechaInicio);
			let fechaFin = new Date(this.fechaFin);
			let ejecutarQuery = false;

			if (fechaInicio > fechaFin) {
				alert("La fecha de inicio tiene que ser antes de la fecha de fin");
			} else {
				if (this.fechaInicio === null && this.fechaFin === null) {
					this.dataInicio = 0;
					this.dataFin = 20;
					this.fechaInicio = null;
					this.fechaFin = null;
					this.utilidadesQuery = {};
					ejecutarQuery = true;
					this.mostrarTotal = false;
				} else {
					if (this.fechaInicio === null || this.fechaFin === null) {
						alert("Por favor elegir fecha de inicio y fin.");
					} else {
						ejecutarQuery = true;
						this.mostrarTotal = true;
					}
				}
			}
			if (ejecutarQuery) {
				this.queryUtilidades();
			}
		},
		async queryUtilidades() {
			this.message = "Cargando, por favor espere";
			this.showMessage = true;
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/utilidadesQuery.gql"),
					variables: {
						dataInicio: this.dataInicio,
						dataFin: this.dataFin,
						filtro: this.filtro,
						fechaInicio: this.fechaInicio,
						fechaFin: this.fechaFin,
						cliente_id: this.clienteId
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.utilidadesQuery = data.data.utilidadesQuery;
					this.message = "Completado";
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.error(JSON.parse(JSON.stringify(error)));
					this.message = "Error de comunicación, por favor intente nuevamente.";
				});
		},
		cargarDataAnterior() {
			this.dataInicio -= 20;
			this.dataFin -= 20;
			this.queryUtilidades();
		},
		cargarDataSiguiente() {
			this.dataInicio += 20;
			this.dataFin += 20;
			this.queryUtilidades();
		}
	}
};
</script>
